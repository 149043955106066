<script>
import axios from "axios";
import { authHeader } from "../../helpers/auth-header";

export default {
    data() {
        return {
            entries_options: [10, 25, 50, 100],
            entries_selected: 10,
            items: [],
            current_page: 1,
            total_row: 0,
            is_busy: false,
            sort_by: "id",
            sort_desc: true,
            has_permission : true,
        };   
    },
    props: {
        entries: {
            required: true,
            type: Number
        },
        path: {
            required: true,
            type: String
        },
        fields: {
            required: true,
            type: Array
        },
        filter: {},
    },
    mounted() {
        //overwrite entries selected if have value
        
    },
    async created() {
        if (this.entries) {
            this.entries_selected = this.entries;
        }
        await this.loadTable();
    },

    methods: {
        async loadTable(pg) {
            this.is_busy = true;
            if (pg != null) this.current_page = pg;
            let tb = {
                "sort": this.sort_by,
                "sort_order":  this.sort_desc ? 'desc' : 'asc',
                "length": this.entries_selected,
                "page": this.current_page,
                "filter": this.filter
            }
            const getList = await axios.get(`${process.env.VUE_APP_APIURL}/${this.path}`, 
                {
                    headers: authHeader(),
                    params: tb
                }
            );
            if (getList.status == 200) {
                this.is_busy = false;
                if (getList.data.code == 'success') {
                    let { data } = getList.data.data;
                    const { total } = getList.data.data;
                    this.total_row = total
                    data.map(obj => {
                        for (let key in obj) {
                            if (obj[key] == null) {
                                obj[key] = '-';
                            }
                        }
                        return obj;
                    });
                    this.items = data; 
                } else if (getList.data.code == 'permission_denied')  {
                    this.has_permission = false;
                }
            } else if (getList.status == 204) {
                this.items = [];
                this.is_busy = false;
            }
        },

        async showMore(val) {
            console.log('valllll', val)
            this.entries_selected = val;
            this.current_page = 1; // Reset to the first page
            this.loadTable();
        },
        sortChanged() {
            // this.sort_by = ctx.sort_by;
            // this.sort_desc = ctx.sort_desc;
            this.loadTable(1);
        },
    },
    watch: {
        current_page: {
            handler: function(val) {
                this.loadTable(val);
            }
        },
        // filter: {
        //     handler: function(val) {
        //         console.log(val);
        //     }
        // }
    }
};
</script>

<template>
    <div class="row table-responsive">
        <div class="col-12">
            <label class="d-flex align-items-center">
                Show 
                <b-form-select v-model="entries_selected" :options="entries_options" class="table_entries" size="sm" @change="showMore"></b-form-select>
                entries
            </label>
            <b-table hover responsive :fields="fields" :items="items" :busy="is_busy" :sort-by.sync="sort_by" :sort-desc.sync="sort_desc" @sort-changed="sortChanged" :per-page="entries_selected"  show-empty>
                <template #cell(status)="data">
                    <div class="badge font-size-12 " 
                        :class="{
                            'badge-danger': parseInt(`${data.value}`) === 0,
                            'badge-success': parseInt(`${data.value}`) === 1,
                        }">
                        {{ parseInt(data.value) === 1 ? 'Active' : 'Inactive' }}
                    </div>
                </template>
                <template #table-busy>
                    <div class="text-center my-2">
                        <b-spinner class="align-middle mr-2"></b-spinner>
                        <strong>Loading...</strong>
                    </div>
                </template>
                <template v-for="(index, name) in $scopedSlots" v-slot:[name]="data">
                    <slot :name="name" v-bind="data"></slot>
                </template>
                <template #empty="scope">
                    <div class="has-text-centered text-center" v-if="has_permission == true">{{ scope.emptyText }}</div>
                    <div class="has-text-centered text-center" v-else>Permission Denied</div>
                </template>
            </b-table>
            <div class="dataTables_paginate paging_simple_numbers float-right">
                <ul class="pagination pagination-rounded mb-0">
                    <b-pagination v-model="current_page" :total-rows="total_row" align="right" :per-page="entries_selected" class="new-list-page-active"></b-pagination>
                </ul>
            </div>
        </div>
    </div>
</template>


<style lang="scss">
.table_entries {
    width: 60px;
    margin: 0 8px;
}

.new-list-tb thead th, .new-list-tb td {
    border-top: 1px solid #CDCDCD80;
    border-bottom: 1px solid #CDCDCD80;
}

.new-list-tb thead th div {
    font-weight: 700;
    font-family: "Nunito";
    color: #2D3748;
}

.new-list-tb td {
    font-weight: 600;
    font-family: "Nunito";
    color: #2D3748;
   
}

.new-list-page-active .page-item.active .page-link {
    background: #FE4727;
}
</style>